<template>  
  <svg v-if="name === 'share'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.98 25.94"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><circle cx="15.99" cy="3.99" r="3.99"/><circle cx="3.99" cy="12.97" r="3.99"/><circle cx="15.99" cy="21.95" r="3.99"/><polyline points="15.99 21.95 3.99 12.97 15.99 3.99" style="fill:none;stroke-miterlimit:10" class="line" /></g></g></svg> 
  <svg v-else-if="name === 'kakao'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.07 65.63"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M35.53,0C15.91,0,0,12.57,0,28.07,0,38,6.58,46.8,16.49,51.78L13.14,64.29a1,1,0,0,0,.25,1,1,1,0,0,0,.72.29,1.06,1.06,0,0,0,.63-.21l14.41-9.73a44.47,44.47,0,0,0,6.38.45c19.63,0,35.54-12.56,35.54-28.07S55.16,0,35.53,0ZM17.6,35.51a1.61,1.61,0,0,1-1.7,1.77,1.61,1.61,0,0,1-1.7-1.77V23.16H10.62A1.44,1.44,0,0,1,9.1,21.62a1.51,1.51,0,0,1,1.52-1.55H21.17a1.5,1.5,0,0,1,1.52,1.55,1.44,1.44,0,0,1-1.52,1.54H17.6ZM34,37.28a1.58,1.58,0,0,1-1.66-1.18l-.74-2.32h-7.1l-.76,2.3a1.56,1.56,0,0,1-1.64,1.2,1.6,1.6,0,0,1-1.67-1.57,1.77,1.77,0,0,1,.16-.74L25.56,21.9a2.78,2.78,0,0,1,2.53-2,2.67,2.67,0,0,1,2.53,2l4.78,13a2.85,2.85,0,0,1,.19.88A1.49,1.49,0,0,1,34,37.28Zm11.44-.19H39.14a1.68,1.68,0,0,1-1.78-1.88V21.69a1.7,1.7,0,1,1,3.4,0V34h4.72a1.61,1.61,0,0,1,1.22.47,1.51,1.51,0,0,1,.4,1.14A1.55,1.55,0,0,1,45.48,37.09Zm14.08.19h-.27c-.63-.06-1-.46-1.63-1.34l-4.47-6.09L52,31.08v4.58a1.62,1.62,0,0,1-1.7,1.62,1.57,1.57,0,0,1-1.7-1.62V21.59a1.63,1.63,0,0,1,1.7-1.67A1.59,1.59,0,0,1,52,21.59V27l6-6.24a1.85,1.85,0,0,1,1.35-.62,1.54,1.54,0,0,1,1.62,1.56c0,.47-.28.78-.83,1.39l-.42.47-4.09,4.13,4.84,6.33A2.65,2.65,0,0,1,61.25,36,1.55,1.55,0,0,1,59.56,37.28Z"/><path class="cls-1" d="M28.05,23l-2.56,7.74h5.2L28.12,23A0,0,0,0,0,28.05,23Z"/></g></g></svg>
  <svg v-else-if="name === 'clip'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.21 79.58"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect y="67.09" width="190.21" height="12.49" class="clip-bottom"/><path d="M178.55,23.73H119a23.88,23.88,0,0,0-47.75,0H11.65A11.64,11.64,0,0,0,0,35.38V67.09H190.21V35.38A11.65,11.65,0,0,0,178.55,23.73ZM95.1,13.26a10.61,10.61,0,0,1,10.61,10.47v.14a10.61,10.61,0,1,1-21.22,0v-.14A10.61,10.61,0,0,1,95.1,13.26Z" class="clip-top"/></g></g></svg>
</template>

<script>
export default {
  props: {
    name: String,
  },
};
</script> 