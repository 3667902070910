<template>
  <div>
    <div v-if="!eventResult">
      <Header />
      <div class="snack-event">
        <section class="event-main">
          <div class="wrapper">
            <div class="event-main__title">
              <div class="img-ribbon">
                <img
                  src="@/assets/images/main/img_ribbon_event.png"
                  alt="EVENT"
                  class="block-img"
                />
              </div>
              <div class="sub-title">
                <img src="@/assets/images/main/ic_test_tube.png" /><strong
                  >실험에 진심</strong
                >인 여러분을 위한
              </div>
              <div class="title">머크의 간식 응원 이벤트</div>
            </div>
            <div class="event-main__date">
              <div class="date">
                <div class="label">이벤트 마감일</div>
                <div class="text"><span class="vdn">7/31</span> (일)</div>
              </div>
              <div class="date">
                <div class="label">간식 증정일</div>
                <div class="text"><span class="vdn">8/9</span> (화)</div>
              </div>
            </div>
          </div>
        </section>
        <section class="event-gift">
          <div class="gifts">
            <img src="@/assets/images/main/img_gifts.png" class="pc" />
            <img src="@/assets/images/main/img_gifts_m.png" class="mo" />
          </div>
        </section>
        <section class="event-content" data-app>
          <div class="wrapper">
            <div class="event-content__form">
              <Icon name="clip" class="icon-clip" />
              <div class="text-required">* 필수 입력 항목</div>
              <v-form
                ref="form"
                v-model="valid"
                class="event-form"
                lazy-validation
              >
                <label class="event-form__label"
                  ><span class="event-form__label-accent">* </span>이름</label
                >
                <v-text-field
                  v-model="form.name"
                  :rules="nameRules"
                  color="#2DBECD"
                  class="event-form__input"
                  required
                ></v-text-field>
                <label class="event-form__label"
                  ><span class="event-form__label-accent">* </span>학교</label
                >
                <v-text-field
                  v-model="form.school"
                  :rules="schoolRules"
                  color="#2DBECD"
                  class="event-form__input"
                  required
                ></v-text-field>
                <label class="event-form__label"
                  ><span class="event-form__label-accent">* </span>소속교수님
                  성함</label
                >
                <v-text-field
                  v-model="form.professor"
                  :rules="profRules"
                  color="#2DBECD"
                  class="event-form__input"
                  required
                ></v-text-field>
                <label class="event-form__label"
                  ><span class="event-form__label-accent">* </span
                  >휴대폰번호</label
                >
                <v-text-field
                  type="tel"
                  v-model="form.phone"
                  :rules="phoneRules"
                  color="#2DBECD"
                  class="event-form__input"
                  validate-on-blur
                  hint="하이픈(-) 제외"
                  required
                ></v-text-field>
                <label class="event-form__label">이메일 주소</label>
                <v-text-field
                  type="email"
                  v-model="form.email"
                  color="#2DBECD"
                  class="event-form__input"
                  validate-on-blur
                ></v-text-field>
                <div class="form-options">
                  <div class="form-options__title">
                    <span class="vdn">7</span>월 제품 프로모션 자료 받기
                  </div>
                  <div class="form-options__lists">
                    <div class="option">
                      <input
                        type="checkbox"
                        id="checkOption1"
                        name="checkOption1"
                        v-model="form.checkOption1"
                      />
                      <label for="checkOption1">
                        <span class="checkbox"></span>
                        <div class="text">
                          <span class="vdn">Cell Culture</span> 제품 샘플 신청
                        </div>
                      </label>
                    </div>
                    <div class="option">
                      <input
                        type="checkbox"
                        id="checkOption2"
                        name="checkOption2"
                        v-model="form.checkOption2"
                      />
                      <label for="checkOption2">
                        <span class="checkbox"></span>
                        <div class="text">
                          <span class="vdn">ZooMAb<sup>®</sup> Antibody</span> 할인
                        </div>
                      </label>
                    </div>
                    <div class="option">
                      <input
                        type="checkbox"
                        id="checkOption3"
                        name="checkOption3"
                        v-model="form.checkOption3"
                      />
                      <label for="checkOption3">
                        <span class="checkbox"></span>
                        <div class="text">
                          <span class="vdn">50%</span> 할인 행사
                          <span class="vdn"
                            >(Catalysts, Reagent, Solvent, Protein,<br
                              class="pc"
                            />Kit</span
                          >
                          등)
                        </div>
                      </label>
                    </div>
                    <div class="option">
                      <input
                        type="checkbox"
                        id="checkOption4"
                        name="checkOption4"
                        v-model="form.checkOption4"
                      />
                      <label for="checkOption4">
                        <span class="checkbox"></span>
                        <div class="text">
                          견적 및 기타문의 (대체품 상담, 대용량 견적 등)
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <v-btn
                  :disabled="true"
                  @click="eventDone" 
                  color="#2DBECD"
                  depressed
                  class="event-form__button"
                  >이벤트 신청</v-btn
                >
              </v-form>
              <div class="contact-manager">
                <v-btn
                  color="#ffe400"
                  depressed
                  class="btn-kakao-manager"
                  v-if="!showManagerQRcode"
                  @click="showManagerQRcode = true"
                >
                  <Icon name="kakao" class="icon-kakao" />
                  담당자에게 문의하기
                </v-btn>
                <div class="manager-link" v-else-if="mobile">
                  <v-btn
                    class="manager-link__button"
                    link
                    href="http://bit.ly/MERCKYM"
                    target="_blank"
                    color="#f2f2f2"
                    rounded
                    depressed
                  >
                    <img
                      src="@/assets/images/form/icon_kakao.png"
                      alt="kakao"
                      class="icon-kakao"
                    />김영민 담당자<img
                      src="@/assets/images/form/icon_arrow.png"
                      alt=">"
                      class="icon-arrow"
                    />
                  </v-btn>
                  <v-btn
                    class="manager-link__button"
                    link
                    href="http://qr.kakao.com/talk/9aGVKmKoquxEPJc3.VB1HKMXIII-"
                    target="_blank"
                    color="#f2f2f2"
                    rounded
                    depressed
                  >
                    <img
                      src="@/assets/images/form/icon_kakao.png"
                      alt="kakao"
                      class="icon-kakao"
                    />이효진 담당자<img
                      src="@/assets/images/form/icon_arrow.png"
                      alt=">"
                      class="icon-arrow"
                    />
                  </v-btn>
                </div>
                <div class="manager-qrcode" v-else>
                  <div class="code">
                    <img
                      src="@/assets/images/form/img_qr_kym.png"
                      alt="김영민 큐알코드"
                    />
                    <div class="code-name">김영민 담당자</div>
                  </div>
                  <div class="code">
                    <img
                      src="@/assets/images/form/img_qr_lhj.png"
                      alt="이효진 큐알코드"
                    />
                    <div class="code-name">이효진 담당자</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <v-overlay :value="isLoading" :opacity="0.6">
        <v-progress-circular
          :size="50"
          color="#2DBECD"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
    <EventComplete v-else />
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header";
import EventComplete from "@/components/EventComplete";
import Footer from "@/components/Footer";
import Icon from "@/components/Icon";
export default {
  components: {
    Header,
    Footer,
    EventComplete,
    Icon,
  },
  data: () => ({
    mobile: false,
    valid: true,
    isLoading: false,
    eventResult: false,
    showManagerQRcode: false,
    form: {
      name: "",
      school: "",
      professor: "",
      phone: "",
      email: "",
      checkOption1: false,
      checkOption2: false,
      checkOption3: false,
      checkOption4: false,
    },
    nameRules: [(v) => !!v || "이름을 입력해주세요."],
    schoolRules: [(v) => !!v || "학교를 입력해주세요."],
    profRules: [(v) => !!v || "소속교수님 성함을 입력해주세요."],
    phoneRules: [
      (v) => !!v || "휴대폰 번호를 입력해주세요.",
      (v) => /^[0-9]*$/.test(v) || "형식에 맞지않는 번호 입니다.",
      (v) =>
        (v && (v.length === 11 || v.length === 10)) ||
        "형식에 맞지않는 번호 입니다.",
    ],
  }),
  mounted() {
    const pc_device = "win16|win32|win64|mac|macintel";
    const device = navigator.platform;
    if (device) {
      this.mobile =
        pc_device.indexOf(navigator.platform.toLowerCase()) < 0 ? true : false;
    }
  },
  methods: {
    send(form) {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        axios
          .post(
            "https://us-central1-merck-snack.cloudfunctions.net/sendEvent",
            form
          )
          .then(() => {
            this.eventResult = true;
            this.$refs.form.reset();
          })
          .catch(() => {
            alert("다시 시도해주세요.");
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    eventDone() {
      console.log("다음 이벤트에 만나요❤");
    },
  },
};
</script>

<style lang="scss">
.snack-event {
  .event-main {
    background: $lightpink;
    text-align: center;
    background-image: url("../assets/images/main/bg_l.png"),
      url("../assets/images/main/bg_r.png");
    background-position: calc(50% - 565px) center, calc(50% + 565px) center;
    background-size: 330px, auto;
    background-repeat: no-repeat;
    .wrapper {
      padding-top: 60px;
      padding-bottom: 284px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      margin-bottom: 40px;
      .img-ribbon {
        margin: 0 auto;
        margin-bottom: 30px;
        width: 244px;
      }
      .sub-title {
        margin-bottom: 0.444em;
        font-size: 36px;
        font-weight: 400;
        color: $darkblue;
        letter-spacing: -0.045em;
        strong {
          font-weight: 900;
        }
        img {
          width: 0.889em;
          margin-right: 0.278em;
        }
      }
      .title {
        color: $pink;
        font-size: 60px;
        font-weight: 900;
        letter-spacing: -0.045em;
      }
    }
    &__date {
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 20px;
      letter-spacing: -0.045em;
      .date {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      .label {
        min-width: 152px;
        margin-right: 0.7em;
        padding: 0.4em 0.8em;
        border-radius: 999px;
        background: $blue;
        color: $white;
      }
      .text {
        color: $darkblue;
      }
    }
  }
  .event-gift {
    position: relative;
    width: 100%;
    height: 204px;
    text-align: center;
    background: $blue;
    .gifts {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      z-index: 1;
      img {
        max-height: 100%;
        &.pc {
          display: block;
        }
        &.mo {
          display: none;
        }
      }
      .text {
        display: inline-block;
        margin-top: 1.429em;
        padding: 0.9em 1.714em;
        border-radius: 999px;
        background: rgba(255, 220, 185, 0.6);
        font-size: 14px;
        color: $purple;
        letter-spacing: -0.045em;
        .mo {
          display: none;
        }
      }
    }
  }
  .event-content {
    padding-top: 95px;
    background: $blue;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 32px 120px;
    }
    .text-required {
      margin-bottom: 1.571em;
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      color: $pink;
    }
    &__form {
      position: relative;
      width: 100%;
      max-width: 560px;
      padding: 45px 40px 60px;
      text-align: center;
      background: $white;
      border-radius: 8px;
      box-shadow: 0 0 0 20px $lightpink;
      .icon-clip {
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
        width: 190px;
        .clip-top {
          fill: $pink;
        }
        .clip-bottom {
          fill: $blue;
        }
      }
      .event-form {
        text-align: left;
        &__label {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.05em;
          line-height: 1;
          color: $black;
          &-accent {
            color: $pink;
          }
        }
        &__input {
          margin-top: 0;
          padding-top: 10px;
          margin-bottom: 20px;
          font-size: 18px;
          input {
            max-height: 40px;
            line-height: normal;
          }
        }
        &__button {
          display: block;
          margin: 0 auto;
          width: 100%;
          max-width: 240px;
          height: 52px !important;
          border-radius: 8px;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: -0.045em;
          color: $white;
        }
      }
      .contact-manager {
        margin-top: 16px;
        .manager-link {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          &__button {
            height: 36px !important;
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
            letter-spacing: -0.05em;
            color: $black;
            .icon-kakao {
              margin-right: 8px;
              width: 18px;
            }
            .icon-arrow {
              margin-left: 12px;
              width: 6px;
            }
            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
        .btn-kakao-manager {
          height: 52px !important;
          padding-right: 1.667em;
          padding-left: 1.111em;
          background: #ffe400;
          border-radius: 999px;
          font-size: 18px;
          font-weight: 700;
          line-height: 1;
          color: $black;
          letter-spacing: -0.05em;
          .icon-kakao {
            fill: #3c2828;
            margin-right: 14px;
            width: 32px;
          }
        }
        .manager-qrcode {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          max-width: 240px;
          margin: 0 auto;
          margin-top: 40px;
          .code {
            width: calc(50% - 20px);
          }
          .code-name {
            margin-top: 0.571em;
            padding: 0.571em 0;
            border-radius: 99px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: -0.05em;
            background: #f2f2f2;
            color: $black;
          }
        }
      }
      .form-options {
        margin-bottom: 40px;
        &__title {
          margin-bottom: 24px;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: -0.05em;
          line-height: 1.15;
          color: $blue;
          .sub {
            font-size: 16px;
          }
        }
        &__lists {
          .option {
            input {
              display: none;
              & + label {
                display: flex;
                /* align-items: center; */
                cursor: pointer;
                .checkbox {
                  flex-shrink: 0;
                  display: inline-block;
                  width: 24px;
                  height: 24px;
                  vertical-align: middle;
                  border-radius: 4px;
                  background-color: #d4d4d4;
                  background-image: url("../assets/images/form/icon_check.png");
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: 0 1px;
                }
              }
              &:checked + label {
                .checkbox {
                  background-color: $blue;
                }
              }
            }
            .text {
              display: inline-block;
              margin-left: 12px;
              font-size: 16px;
              color: $black;
              letter-spacing: -0.045em;
              line-height: 1.3;
              word-break: keep-all;
              sup {
                top: 0;
              }
            }
            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1440px) {
    .event-main {
      background-position: calc(50% - 520px) center, calc(50% + 520px) center;
    }
  }
  @media (max-width: 1100px) {
    /* .event-main {
      .wrapper {
        padding-bottom: 25.4vw; 
      }
    }
    .event-gift {
      height: 18.2vw;
      .gifts {
        max-width: 54.5vw;
      }
    } */
  }
  @media (max-width: 750px) {
    .event-main {
      background-image: url("../assets/images/main/bg_m.png");
      background-position: center top;
      background-size: 100% auto;
      .wrapper {
        padding-top: 10.6vw;
        padding-bottom: 48vw;
      }
      &__title {
        margin-bottom: 5.33vw;
        .img-ribbon {
          width: 42vw;
          margin-bottom: 5.3vw;
        }
        .sub-title {
          margin-bottom: 0.4em;
          font-size: 5.33vw;
          font-weight: 700;
        }
        .title {
          font-size: 8vw;
          br {
            display: block;
          }
        }
      }
      &__date {
        margin-bottom: 2.308em;
        font-size: 3.46vw;
        .date {
          &:not(:last-child) {
            margin-bottom: 1.6vw;
          }
        }
        .label {
          min-width: 7.692em;
          margin-right: 0.538em;
        }
      }
    }
    .event-gift {
      height: 30.7vw;
      .gifts {
        width: 100%;
        max-width: 89.3vw;
        img {
          max-height: 100%;
          &.pc {
            display: none;
          }
          &.mo {
            display: inline-block;
          }
        }
        .text {
          margin-top: 1.091em;
          padding: 0.545em 1.818em;
          font-size: 2.933vw;
          line-height: 1.3;
          .mo {
            display: block;
          }
        }
      }
    }
    .event-content {
      padding-top: 16vw;
      .wrapper {
        padding: 3.2vw 8.53vw 8.66vw;
      }
      .text-required {
        margin-bottom: 1.2em;
        font-size: 2.7vw;
      }
      &__form {
        max-width: 670px;
        padding: 6.7vw 5.3vw 8vw;
        box-shadow: 0 0 0 3.2vw $lightpink;
        .icon-clip {
          top: -8.53vw;
          width: 28.66vw;
        }
        .event-form {
          &__label {
            font-size: 3.2vw;
          }
          &__input {
            padding-top: 0.533em;
            margin-bottom: 0.8em;
            font-size: 4vw;
          }
          &__button {
            max-width: 13.333em;
            height: 2.533em !important;
            font-size: 4vw;
          }
        }
        .contact-manager {
          margin-top: 2.7vw;
          .btn-kakao-manager {
            width: 100%;
            max-width: 13.333em;
            height: 2.533em !important;
            font-size: 4vw;
            .icon-kakao {
              width: 4.8vw;
              margin-right: 1.86vw;
            }
          }
          .manager-qrcode {
            max-width: 42.66vw;
            .code-name {
              font-size: 2.4vw;
            }
          }
        }
        .form-options {
          margin-bottom: 8vw;
          &__title {
            margin-bottom: 1.067em;
            font-size: 4vw;
            .sub {
              font-size: 0.8em;
            }
          }
          &__lists {
            .option {
              input {
                & + label {
                  align-items: flex-start;
                  .checkbox {
                    width: 4.8vw;
                    height: 4.8vw;
                    border-radius: 0.8vw;
                  }
                }
              }
              .text {
                margin-left: 0.577em;
                font-size: 3.467vw;
              }
              &:not(:last-child) {
                margin-bottom: 3.73vw;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .event-content {
      &__form {
        .icon-clip {
          top: -9.2vw;
          width: 30vw;
        }
        .event-form {
          &__label {
            font-size: 3.6vw;
          }
          &__input {
            font-size: 4.4vw;
          }
          &__button {
            font-size: 4.2vw;
          }
        }
        .contact-manager {
          margin-top: 3vw;
          .btn-kakao-manager {
            font-size: 4.2vw;
          }
        }
      }
    }
  }
}
</style>
