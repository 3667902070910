<template>
  <header>
    <div class="wrapper">
      <h1><logo class="logo" /></h1>
    </div>
  </header>
</template>
<script>
import Logo from "@/components/Logo";
export default {
  components: {
    Logo,
  },
};
</script> 
<style lang="scss">
header {
  .wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .logo {
    display: block;
    fill: currentColor;
    color: $blue;
    height: 25px;
  }
  @media (max-width: 1100px) {
    .wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .logo {
      height: 20px;
    }
  }
}
</style>
