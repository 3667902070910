<template>
  <div>
    <overlay-next />
    <snack-event />
  </div>
</template>

<script>
import OverlayNext from "./components/OverlayNext.vue";
import SnackEvent from "./components/SnackEvent";

export default {
  name: "App",

  components: {
    SnackEvent,
    OverlayNext,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
html,
body {
  overflow: hidden;
}
</style>
